import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import ShareButtons from "../../components/ShareButtons"
import Content, { HTMLContent } from "../../components/Content";
import * as styles from "./Basic.module.scss"

import bgImage from "../../../static/assets/hero-banner.png"

//images
import jakob from "/static/assets/jakob-profil.png"
import bjorn from "/static/assets/torbjorn-profil.png"
import frida from "/static/assets/frida-profil.png"
import stefan from "/static/assets/stefan-profil.png"
import mathilda from "/static/assets/mathilda-profil.png"

export const pageQuery = graphql`query BasicQuery($id: String!) {
  markdownRemark(id: {eq: $id}) {
    id
    html
    excerpt(pruneLength: 140)
    frontmatter {
      title
      slug
      metaTitle
      metaDescription
      socialMedia
      pageType
      date(formatString: "D MMM")
      featuredImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      sidebarCard {
        body
        pageType
        checkboxes {
          text
          icon {
            childImageSharp {
              gatsbyImageData(width: 50, layout: CONSTRAINED)
            }
          }
        }
        footer
        linkPath
        linkTitle
        remark
        title
      }
      heroBanner {
        title
        linkPath
        linkTitle
        pageType
      }
      subTitle
      subTitleBody
      bottomTitle
      bottomSubtitle
      announcement
      authorOfQuote {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      quote
    }
  }
}
`

const HeroBannerData = {
  sellPage: {
    title: "Avveckla aktiebolag - Sälj bolaget till oss för snabbavveckling!",
    buttonText: "LÄS MER",
    link: "/avveckla-aktiebolag",
  },
  buyPage: {
    title: "Lagerbolag – Det enkla sättet att starta aktiebolag!",
    buttonText: "BESTÄLL OFFERT",
    link: "/kopa-lagerbolag",
  },
}

const ContentBottomData = {
  sellPage: {
    title: "Avveckla Ditt Aktiebolag idag!",
    buttonText: "BESTÄLL OFFERT",
    link: "/avveckla-aktiebolag",
  },
  buyPage: {
    title: "Köp ditt lagerbolag av oss idag!",
    buttonText: "Beställ Nu",
    link: "/kopa-lagerbolag",
  },
}

export const BasicPageTemplate = ({
  pageType,
  subTitle,
  subTitleBody,
  announcement,
  title,
  authorOfQuote,
  quote,
  socialMedia,
  featuredImage,
  slug,
  html,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;
  
  return (
    <div className={styles.Basic}>
      {pageType === "moreInformationSubPage" && (
        <div className={styles.subPage}>
          <h2 className={styles.title}>{subTitle}</h2>
          <p>{subTitleBody}</p>
          <p className={styles.announcement}>{announcement}</p>
  
          {typeof authorOfQuote === 'string'
            ? (
              // This fallback is for NetlifyCMS preview:
              <img src={authorOfQuote} alt={title} className={styles.authorOfQuote} />
            ) : (
              <GatsbyImage
                className={styles.authorOfQuote}
                alt={title}
                image={authorOfQuote.childImageSharp.gatsbyImageData}
              />
            )
          }
          
          <p>{quote}</p>
        </div>
      )}
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.underLine} />
      {featuredImage && (
        <div className={styles.imageContainer}>
          {typeof featuredImage === 'string'
            ? (
              // This fallback is for NetlifyCMS preview:
              <img src={featuredImage} alt={title} className={styles.image} />
            ) : (
              <GatsbyImage
                image={featuredImage.childImageSharp.gatsbyImageData}
                alt={title}
                className={styles.image}
              />
            )
          }
        </div>
      )}
      {socialMedia ? (
        <div className={styles.contentContainer}>
          <ShareButtons
            title={title}
            url={String(typeof window !== "undefined" && window.location)}
            featuredImage={
              featuredImage?.childImageSharp?.gatsbyImageData.src
            }
          />
          <PageContent content={ html } />
        </div>
      ) : slug === "/medarbetare" ? (
        <PageContent content={ html } className={styles.textInlineWithImage}/>
      ) : (
        <PageContent content={ html } className={styles.quote}/>
      )}
    </div>
  )
};

const Basic = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const readyToLiquidateSellMock = {
      title: frontmatter.bottomTitle,
      subtitle: frontmatter.bottomSubtitle,
      imgs: [jakob, bjorn, frida, stefan, mathilda],
      bottom_title: "Avveckla Ditt Aktiebolag idag!",
      bottom_subtitle:
        "Vi har ett marknadsledande erbjudande på snabbavveckling av aktiebolag. Vi återkommer med en kostnadsfri offert inom 2 timmar!",
  }

  const readyToLiquidateBuyMock = {
      title: frontmatter.bottomTitle,
      subtitle: frontmatter.bottomSubtitle,
      imgs: [jakob, bjorn, frida, stefan, mathilda],
      bottom_title: "Köp ditt lagerbolag av oss idag!",
      bottom_subtitle:
        "Vi har ett marknadsledande erbjudande på våra lagerbolag. Vi kan ha ditt nya bolag klart inom 2 timmar!",
  }

  return (
    <Layout
      sidebar
      showBottomContent={
        frontmatter?.socialMedia ||
        frontmatter?.pageType === "moreInformationSubPage"
      }
      showTopContent={
        frontmatter?.socialMedia ||
        frontmatter?.pageType === "moreInformationSubPage"
      }
      pageType={frontmatter?.pageType}
      heroData={
        frontmatter?.heroBanner?.length > 0
          ? frontmatter?.heroBanner
          : HeroBannerData
      }
      contentBottomData={ContentBottomData}
      bgImage={bgImage}
      moreInformationSubPage={frontmatter}
      readyToLiquidateMock={
        frontmatter?.heroBanner?.length > 0 &&
        frontmatter.heroBanner[0]?.pageType === "buy"
          ? readyToLiquidateBuyMock
          : readyToLiquidateSellMock
      }
    >
      <SEO
        title={frontmatter.metaTitle ? frontmatter.metaTitle : frontmatter.title}
        description={frontmatter.metaDescription}
      />

      <BasicPageTemplate
        pageType={frontmatter?.pageType}
        subTitle={frontmatter?.subTitle}
        subTitleBody={frontmatter?.subTitleBody}
        announcement={frontmatter?.announcement}
        title={frontmatter?.title}
        authorOfQuote={frontmatter?.authorOfQuote}
        quote={frontmatter?.quote}
        socialMedia={frontmatter?.socialMedia}
        featuredImage={frontmatter?.featuredImage}
        slug={frontmatter?.slug}
        html={html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
}

export default Basic
