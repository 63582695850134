// extracted by mini-css-extract-plugin
export var Basic = "Basic-module--Basic--2ynDe";
export var subPage = "Basic-module--subPage--lmqGb";
export var announcement = "Basic-module--announcement--3nqCl";
export var authorOfQuote = "Basic-module--authorOfQuote--28XEe";
export var textInlineWithImage = "Basic-module--textInlineWithImage--2VtX8";
export var quote = "Basic-module--quote--1ItaW";
export var contentContainer = "Basic-module--contentContainer--1RXTj";
export var title = "Basic-module--title--2EUEX";
export var underLine = "Basic-module--underLine--2aa11";
export var imageContainer = "Basic-module--imageContainer--2XlGj";
export var image = "Basic-module--image--15F57";
export var date = "Basic-module--date--2if-w";
export var day = "Basic-module--day--1HcnK";
export var month = "Basic-module--month--1Pl3U";